import React from 'react';
import { WorkSchedule } from 'admin/src/types';
import { cn } from 'shared/src/utils';

import PollworkerScheduleListItem from './PollworkerScheduleListItem';

export default PollworkerScheduleList;

export interface PollworkerScheduleListProps {
  className?: string | undefined;
  schedules: WorkSchedule[];
  onPollworkerClick: (schedule: WorkSchedule) => void;
  onClockIn: (schedule: WorkSchedule) => Promise<void>;
  onClockOut: (schedule: WorkSchedule) => Promise<void>;
}

function PollworkerScheduleList({ className, schedules, onPollworkerClick, onClockIn, onClockOut }: PollworkerScheduleListProps) {
  return (
    <div className={cn(className, 'flex flex-col bg-white border rounded-lg divide-y divide-gray w-full overflow-auto')}>
      {schedules.map(schedule => (
        <PollworkerScheduleListItem schedule={schedule} onPollworkerClick={onPollworkerClick} onClockIn={onClockIn} onClockOut={onClockOut} />
      ))}
    </div>
  );
}
