import { fetchApi } from 'shared/src/fetchers';
import { PollworkerTimeclockEntry, Precinct, VotingLocation, WorkSchedule } from 'admin/src/types';

export const fetchVotingLocationsForElectionAndWorkDate = (electionId: string, workDateStr: string): Promise<VotingLocation[]> => {
  return fetchApi(`/di-api/pollworker/workschedules/election/${electionId}/date/${workDateStr}/locations`, {})
    .then(async response => await response.json() as VotingLocation[]);
}

export const fetchPrecinctsForElectionAndWorkDate = (electionId: string, workDateStr: string): Promise<Precinct[]> => {
  return fetchApi(`/di-api/pollworker/workschedules/election/${electionId}/date/${workDateStr}/precincts`, {})
    .then(async response => await response.json() as Precinct[]);
}

export const fetchWorkSchedulesForLocationAndDate = (locationId: string, workDateStr: string): Promise<WorkSchedule[]> => {
  return fetchApi(`/di-api/pollworker/workschedules/bylocationanddate/${locationId}/${workDateStr}`, {})
    .then(async response => await response.json() as WorkSchedule[]);
}

export const clockInSchedule = async (scheduleId: string): Promise<PollworkerTimeclockEntry> => {
  return fetchApi(`/pollworker/timeclock/clockin/${scheduleId}`, {
    method: 'POST'
  }).then(async response => (await response.json()) as PollworkerTimeclockEntry);
};

export const clockOutSchedule = async (scheduleId: string): Promise<PollworkerTimeclockEntry> => {
  return fetchApi(`/pollworker/timeclock/clockout/${scheduleId}`, {
    method: 'POST'
  }).then(async response => (await response.json()) as PollworkerTimeclockEntry);
};
