import {Button, DatePicker, Dialog} from "shared/src/components/ui";
import {Flexor} from "shared/src/components";
import {BuildingOffice2Icon} from "@heroicons/react/24/outline";
import {Heading} from "shared/src/components/SectionHeading";
import React, {useState} from "react";
import {downloadDocument} from "shared/src/utils";
import { downloadTrackerExportCSV } from 'admin/src/fetchers';
import { FileDownIcon } from 'lucide-react';
import { SimpleTippyContent } from 'shared/src/components/TippyContent';

export default TrackerExportButton;

export interface TrackerExportProps {
  electionId: string;
}

function TrackerExportButton({electionId }: TrackerExportProps) {
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [exportIsRunning, setExportIsRunning] = useState<boolean>(false);

  function generateExport(electionId: string, startDate: Date | undefined, endDate: Date | undefined) {
    setExportIsRunning(true);
    const reportStartDate: Date = startDate ? startDate : new Date();
    const reportEndDate: Date = endDate ? endDate : new Date();

    downloadTrackerExportCSV(electionId, reportStartDate, reportEndDate).then(responseBody => {
      downloadDocument(`TrackerExport`, '.csv')(responseBody);
    }).finally(() => {
      setDialogOpen(false);
      setExportIsRunning(false);
    });
  }

  const openDialog = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setDialogOpen(true);
  };

  return (
    <Dialog.Dialog variant={'default'} size={'sm'} open={dialogOpen}>
        <Dialog.DialogTrigger asChild>
          <SimpleTippyContent content="Export Timeclock Entries">
            <FileDownIcon onClick={openDialog} className="h-5 w-5" />
          </SimpleTippyContent>
        </Dialog.DialogTrigger>
        <Dialog.DialogContent>
          <Dialog.DialogHeader>
            <Dialog.DialogTitle>
              <BuildingOffice2Icon/>
              <Heading>Export Tracker Data</Heading>
            </Dialog.DialogTitle>
            <Dialog.DialogDescription>
              Select a start and end date below and click Export Data to generate a report of all data for the active election within those specified dates
            </Dialog.DialogDescription>
          </Dialog.DialogHeader>
          <Flexor className='mt-10'>
            <div className={'h-[150px]'}>
              Start Date
              <div className='w-[160px]'>
                <DatePicker disabled={exportIsRunning} onSelect={newDate => setStartDate(newDate)}/>
              </div>
            </div>
            <div className={'h-[150px]'}>
              End Date
              <div className='w-[160px]'>
                <DatePicker disabled={exportIsRunning} onSelect={newDate => setEndDate(newDate)}/>
              </div>
            </div>
          </Flexor>
          <Dialog.DialogFooter>
            <Button variant='primary' type="submit"
                    onClick={() => generateExport(electionId, startDate, endDate)}
                    disabled={exportIsRunning}>Export Data</Button>
          </Dialog.DialogFooter>
        </Dialog.DialogContent>
      </Dialog.Dialog>
  )
}
