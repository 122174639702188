import React, { useCallback, useMemo, useState } from 'react';
import { PollworkerTimeclockEntry, WorkSchedule } from 'admin/src/types';
import { classNames, cn } from 'shared/src/utils';
import dayjs from 'dayjs';
import { Button } from 'shared/src/components/ui';
import { Spinner } from 'shared/src/components';
import Timeclock from './TimeClock'

export default PollworkerScheduleListItem;

export interface PollworkerScheduleListItemProps {
  className?: string | undefined;
  schedule: WorkSchedule;
  onPollworkerClick: (schedule: WorkSchedule) => void;
  onClockIn: (schedule: WorkSchedule) => Promise<void>;
  onClockOut: (schedule: WorkSchedule) => Promise<void>;
}

function PollworkerScheduleListItem({ className, schedule, onPollworkerClick, onClockIn, onClockOut }: PollworkerScheduleListItemProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const mostRecentTimeclockEntry = useMemo(
    () => schedule?.timeclockEntries?.filter(x => x.timeIn)
            .sort((a: PollworkerTimeclockEntry, b: PollworkerTimeclockEntry) => (a.timeIn as string) > (b.timeIn as string) ? 1 : -1)
            .pop(),
      [schedule]
  );

  const wrongDate = useMemo(
    () => {
      const scheduledDate = schedule.workDate.split('T')[0];

      return scheduledDate != dayjs().format('YYYY-MM-DD');
    },
    [schedule]
  );

  const handleClockIn = useCallback(() => {
    setLoading(true);

    onClockIn(schedule).finally(() => {
      setLoading(false);
    });
  }, [onClockIn]);

  const handleClockOut = useCallback(() => {
    setLoading(true);

    onClockOut(schedule).finally(() => {
      setLoading(false);
    });
  }, [onClockOut]);

  const [clockTime, clockedOut, actionLabel, backgroundColor] = useMemo(() => {
    let backgroundColor = 'bg-gray-500';
    const clockedOut = !mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.timeOut;
    let clockTime: number;

    if (!mostRecentTimeclockEntry) {
      const startTime = dayjs(schedule.startTime, "h:mm A")
      clockTime = dayjs(schedule.workDate)
        .add(startTime.hour(), 'hours')
        .add(startTime.minute())
        .toDate().getTime();
    } else {
      clockTime = dayjs(mostRecentTimeclockEntry.timeIn).toDate().getTime();
    }

    if (mostRecentTimeclockEntry && !mostRecentTimeclockEntry.timeOut) {
      backgroundColor = 'bg-green-500';
    } else if (mostRecentTimeclockEntry) {
      backgroundColor = 'bg-yellow-600';
    } else if (dayjs().toDate().getTime() > clockTime) {
      backgroundColor = 'bg-red-500';
    }

    return [
      clockTime,
      clockedOut,
      wrongDate
        ? 'Clock In/Out Unavailable'
        : (!mostRecentTimeclockEntry || !!mostRecentTimeclockEntry.timeOut) ? 'Clock In' : 'Clock Out',
      backgroundColor
    ]
  }, [mostRecentTimeclockEntry]);

  return (
    <div className={cn(className, 'flex items-center justify-between p-3 px-4 shadow-sm')}>
      <div className="cursor-pointer" onClick={() => onPollworkerClick(schedule)}>
        <div className='text-lg font-medium'>{schedule.pollworkerFullName}</div>
        <div className='text-sm'>{schedule.roleName}, <i>Schedule: {schedule.startTime}-{schedule.endTime}</i></div>
      </div>
      <div className="flex-grow flex justify-center space-x-4">
        <div className={classNames(backgroundColor, 'text-white px-4 py-1 rounded-md')}>
          <Timeclock scheduledTimeUTC={clockTime} isClockedIn={!clockedOut} />
        </div>
      </div>
      <div>
        <Button disabled={loading || wrongDate} className="w-full" variant="secondary"
          onClick={() => clockedOut ? handleClockIn() : handleClockOut()}>
          <Spinner show={loading} />
            {!loading && actionLabel}
        </Button>
      </div>
    </div>
  )
}
